.highlighted {
    background-size: 200%;
    background-image: linear-gradient(transparent 10%, #ffcf40bb 10%);
}

.planText {
    opacity: 0.9;
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
    text-align: start;
}

/* Effects */

.highlightGold {
    animation-name: highlight;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    background-size: 200%;
    background-image: linear-gradient(to right, #EFEFED 50%, transparent 50%),
        linear-gradient(transparent 10%, #ffcf40bb 10%);
}