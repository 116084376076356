.footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    background-color: #371938;
    color: rgba(255, 255, 255, 0.897);
}

.footerContainer {
    margin: 20px 0;
    padding: 0 40px;
    width: 100%;
    text-align: center;
}

.footerTitle {
    font-family: "Cormorant";
    font-size: 22px;
    margin: 0 0 5px 0;
    text-align: start;
    width: 100%
}

.subTextTable {
    font-size: 0.82em;
    line-height: 1.7em;
    opacity: 0.9;
    margin: 0;
}

.logoFooter {
    width: 22vw;
    object-fit: cover;
    margin-bottom: 20px;
}

.buttonFooter {
    padding: 10px 0px;
    width: 120px;
    border: solid 1.5px rgba(0, 0, 0, 0.537);
    color: rgba(255, 255, 255, 0.838);
    border-color: rgba(255, 255, 255, 0.838);
    font-family: "Catamaran";
    opacity: 0.85;
    height: 20px;
    text-decoration: none;
    color: black;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.buttonFooter>p {
    color: white;
    font-size: 0.7em;
    padding: 0;
    margin: 0
}

/* PC */

@media screen and (min-width: 500px) {
    .footer {
        flex-wrap: nowrap;
    }

    .footerContainer {
        margin: 50px 0;
        padding: 0;
        width: auto;
        min-width: 20%;
    }

    .logoFooter {
        width: 9vw;
        object-fit: cover;
    }

}