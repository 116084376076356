.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
}

.navbarSideContainer {
    width: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbarCenterContainer {
    display: flex;
    justify-content: center;
    width: 60vw;
}

.logo {
    width: 16vw;
    object-fit: cover;
}

.hover-underline-animation,
.navTitle {
    position: relative;
    flex: 1;
    background-color: transparent;
    font-size: 0.7em;
    margin: 0 8px;
    cursor: default;
    font-family: "Catamaran";
    letter-spacing: 1.5px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.underline {
    position: absolute;
    width: 100%;
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: black;
}

a {
    text-decoration: none;
}

.navbarSideContainer>a {
    margin: 3px 0
}

.buttonFlashy {
    display: flex;
    justify-content: center;
    padding: 6px 3px;
    align-items: center;
    height: 20px;
    min-width: 8vw;
    border: solid 1.5px rgba(0, 0, 0, 0.537);
    font-family: "Catamaran";
    opacity: 0.85;
    font-size: 11px;
    margin: 0 5px;
    color: black;
}

.buttonFlashy>p {
    font-family: "Catamaran";
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 0.95em;
    display: none;
}

.buttonFlashy:hover {
    animation: headShake 1 1s;
    cursor: pointer;
}

.buttonImg {
    width: 5vw
}

/* PC */

@media screen and (min-width: 500px) {
    .navbarCenterContainer {
        width: 70vw
    }

    .navbarSideContainer {
        width: 15vw
    }

    .logo {
        width: 7vw
    }

    .hover-underline-animation,
    .navTitle {
        font-size: 1em;
        margin: 0 35px;
        flex: 0 1 auto
    }

    .buttonFlashy {
        padding: 15px;
        align-items: center;
        border: solid 1.5px rgba(0, 0, 0, 0.537);
        font-family: "Catamaran";
        opacity: 0.85;
        font-size: 11px;
        margin: 0 5px;
        color: black;
    }

    .buttonFlashy:hover {
        opacity: 1;
        border: solid 2.5px rgba(0, 0, 0, 0.537);
        padding: 14px;
        font-weight: 700;
    }

    .buttonFlashy>p {
        display: block;
        margin-left: 8px;
    }

    .buttonImg {
        width: 2vw
    }

}


@keyframes wipe-enter {
    0% {
        transform: scale(0, .025);
    }

    50% {
        transform: scale(1, .025);
    }
}