:root {
  --qode-main-color: #363636;
  --qode-header-light-color: #f5f5f5;
  --qode-header-light-hover-color: #fff;
  --qode-header-dark-color: #5d5d5d;
  --qode-header-dark-hover-color: #000
}

body {
  background-color: #EFEFED;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes wipe-enter {
  0% {
    transform: scale(0, .025);
  }

  50% {
    transform: scale(1, .025);
  }
}