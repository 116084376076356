.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.link {
  text-decoration: none;
  color: black;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-logo {
  margin-bottom: 500px;
}

.App-link {
  width: 100%;
  height: 5em;
  background: red;
}

.wipe-enter {
  animation: wipe-enter 1s 1;
}

.sideMenu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 35%;
  background: white;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  transition: 1s;
  padding: 130px 40px
}